::-webkit-scrollbar {
	width: 13px;
	height: 13px;
}

::-webkit-scrollbar-track {
	background-color: #ebebeb;
}

::-webkit-scrollbar-thumb {
	background-color: #b3b3b3
	
}

::-webkit-scrollbar-button {
	background-color: #ebebeb;
	height: 0px;
	width: 0px
}

::-webkit-scrollbar-button:hover {
	background-color: #ebebeb
}

::-webkit-scrollbar-corner {
	background-color: #ebebeb
}

