/* Fonts */





@font-face {
  font-family: 'ApercuMono';
  src: url('./media/ApercuMono.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'Averta';
  src: url('./media/averta-semibold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  /* Regular */
  font-family: 'Averta';
  src: url('./media/averta-bold.woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Averta';
  src: url('./media/averta-semibolditalic-webfont.woff');
  font-weight: normal;
  font-style: italic;
}

:root{
  interpolate-size:allow-keywords; 
}

.expand {
  height: auto;
  transition: height  0.3s ease-out;
  overflow: hidden;
}

.contract {
  height :0%;
  transition: height 0.3s ease-out; ;
  overflow: hidden;
}


.ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu-selected{
  background-color: #1890ff;
  color: #fff;
}


.ant-radio-wrapper{
margin-right: 0px;
}

span.ant-radio + *{
  padding-right: 6px;
  padding-left: 6px;
}

.ant-checkbox + span{
  padding-right: 6px;
  padding-left: 6px;
}


.grid-line{
  background-color: #dddddd;
}

.grid-lines {
  background-size: 30px 30px;

  background-image: linear-gradient(to bottom, 
  #ddd, 1px, 
  transparent 1px);

}

.txtptrn {
  background-image: url('./media/pattern.png');
}

.sb-row {
  display: flex;
  align-items: stretch;
}

.sb-row .sb-label {
  flex: 0 0 120px;
}

.sb-row .sb-bar-container {
  flex: 1;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
}

.sb-row .sb-bar {
  display: flex;
  height: 10px;
  justify-content: flex-end;
  align-items: flex-end;
}

.sb-val-a {
  background: salmon
}

.sb-val-b {
  background: wheat
}

.sb-val-c {
  background: honeydew
}




/* Overwrites */


.img-select-hover {
  background: rgb(0, 0, 0, 0);
  transition: 0.3s;
  opacity: 0;

}

.img-select-hover:hover {
  background: rgb(0, 0, 0, 0.8);
  transition: 0.3s;
  opacity: 1;
}



.wstab {
  width: 166px;
  text-align: center;
  padding: 4px 0px;
}

.filterpreset {
  background-color: #1890ff;
}

.mid {
  font-weight: 600;
}

.ant-message {
  z-index: 9000;
}

.ant-menu-vertical>.ant-menu-item,
.ant-menu-vertical-left>.ant-menu-item,
.ant-menu-vertical-right>.ant-menu-item,
.ant-menu-inline>.ant-menu-item,
.ant-menu-vertical>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-vertical-left>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-vertical-right>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title {
  height: 30px;
  line-height: 30px;
}

.ant-table-thead th.ant-table-column-has-sorters:hover {
  background-color: #fafafa;
}

.ant-table.ant-table-small .ant-table-thead .ant-table-column-sorters {
  padding: 0px 0px;
}

.ant-table-tbody>tr.ant-table-row:hover>td {
  background: #eaf1f6;
}

.ant-image-preview-mask {
  z-index: 3000;
}

.ant-image-preview-wrap {
  z-index: 3080;
}

.ant-image-preview-mask {
  background-color: rgb(0, 0, 0, 0.8);
}

.anticon-rotate-left {
  display: none;
}

.anticon-rotate-right {
  display: none;
}

.supplementary-collapse .ant-collapse-no-arrow {

  border-bottom: 1px solid #eee;
}

.tinyAutocomplete .ant-select-selector {
  border-radius: 1 !important;
  height: 20px !important;
  font-size: 10px !important;
  line-height: 18px !important;
}

.tinyAutocomplete .ant-select-selection-item {
  line-height: 18px !important;
}

.tinyDropdown {
  width: 200px;
}

.tinyDropdown .ant-select-item {
  font-size: 11px !important;
  padding: 0px 6px;
  min-height: 20px;
}

.select-padding {
  width: 30px;

}

.select-padding .ant-select-selector {
  padding: 0px !important;


}

.ant-table-pagination {
  padding-right: 20px;
}

.highlightInput .ant-select-selector {
  background-color: #4f9818 !important;
  color: #fff;
  border: none !important;
}


.tinyAutocomplete input {
  height: 18px !important;
  width: "27px" !important;
  text-align: left !important;

}

.tinyInput {
  border-radius: 1 !important;
  height: 20px !important;
  font-size: 10px !important;
  margin: 3px 0px !important;
}


.selectedRow {
  background-color: #fff !important;
}

.selectedRow .ant-table-cell {
  background: none !important;
}

.spinwrapper .ant-spin-blur {
  z-index: 1;
  overflow: inherit !important;
  opacity: 1;
}

.spinwrapper .ant-spin-blur::after{
  opacity: 0;
}

.no-divider td {
  border: none !important;
}


.pubstockTable .ant-table-expanded-row .ant-table-cell {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.pubstockTable .ant-table-footer {
  padding: 0px !important;
  background-color: #fafafa !important;
}

.pubstockTable .ant-table-title {
  padding: 0px !important;
  background-color: #fafafa !important;
}



.analyiticsTable th {
  font-size: 13px;
  overflow: hidden;
  color: #555 !important;
  font-weight: normal !important;
  padding: 0px 0px !important;
  padding-left: 8px !important;
 
}

.analyiticsTable td {
  font-size: 11px !important;
  padding: 2px 0px !important;
  padding-left: 8px !important;
  
}




.presetTable th {
  font-size: 13px;
  overflow: hidden;
  color: #666 !important;
  font-weight: normal !important;
  padding: 0px 7px !important;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background-color: #eee !important;
}

.presetTable td {
  font-size: 12px !important;
  padding: 5px 7px !important;
  background-color: #fff !important;
}




.pubstockTable th {
  font-size: 13px;
  overflow: hidden;
  color: #555 !important;
  font-weight: normal !important;
  padding: 0px 0px !important;
  padding-left: 8px !important;
  background-color: #fff !important;
}

.pubstockTable td {
  font-size: 11px !important;
  padding: 2px 0px !important;
  padding-left: 8px !important;
  background-color: #fff !important;
}

.bm-input,
.bm-input:focus,
.bm-input::selection,
.bm-input:focus-visible {
  background: transparent;
  border: none;
  outline: none;
}

.bm-input::selection {
  background: #fff;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow{
  color: #000;
}
.ant-upload.ant-upload-select-picture-card {
  width: 194px;
  height: 194px;
}

.ant-tree-indent-unit {

  width: 5px !important;

}

.ticket {
  line-height: 10px;
  padding: 4px 10px;
  border-radius: 0;
  background-color: #fafafa;
  border: 1px dashed #ddd;
  margin-bottom: 3px;
  font-family: monospace;
  font-size: 12px;
}

.bm-input:focus {
  background: transparent;
  border: none;
}

.miniHistory {
  background: none !important;
}

.miniHistory .ant-table-tbody {
  background: none !important;
}

.miniHistory .ant-table-tbody>tr.ant-table-row {
  background: none !important;
}

.miniHistory .ant-table-tbody>tr.ant-table-row>td {
  font-size: 10px;
  line-height: 10px !important;
  min-width: 28px !important;
  padding: 0px 3px !important;
  background: none !important;
  text-align: right !important;
  border-bottom: none;
}


.miniHistory>th {
  font-size: 13px;
  overflow: hidden;
  color: #aaa !important;
  font-weight: normal !important;
  padding: 0px 2px !important;
  background: none !important;
  text-align: right !important;
}

.disabled {
  pointer-events: none;
  opacity: 0.4;
}

.odd {
  background-color: #fafafa;
}

.even {
  background-color: #eee;
}


.bw {
  color: #000;
}


.layout {
  background-color: #fff;
}

.layoutWrapper {
  border: 1px solid #eee;
  margin-bottom: 20px;
}

.layoutHeader {
  padding-bottom: 0px;
  background-color: #fcfcfc;
  margin: 0px 0px;
  padding: 20px 20px 0px 20px;
  border-bottom: 1px solid #eee;
}

.accountCard {
  /* background-color: #001529; */
  text-align: left;
  padding: 0px;
  padding-left: 10px;
  overflow: hidden;
}

.navWrapper {
  background-color: #001529;
  padding: 5px 20px;
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 55px;
  top: 30px;
  border-bottom: 1px solid #001529;
}

.ant-drawer {
  z-index: 999;
}

.transparentTable {
  background: none !important;
}

.transparentTable .ant-table {
  background: none !important;
}

.transparentTable .ant-table-tbody {
  background: none !important;
}

.transparentTable .ant-table-tbody>tr.ant-table-row {
  background: none !important;
}

.transparentTable .ant-table-tbody>tr.ant-table-row>td {
  font-size: 11px;
  line-height: 20px !important;
  min-width: 28px !important;
  padding: 0px 3px !important;
  background: none !important;

  border-bottom: none;
}

.noInteract {
  pointer-events: none;
}

/* .transparentTable > th{
  font-size: 13px;
  overflow: hidden;
  color: #aaa !important;
  font-weight: normal !important;
  padding: 0px 2px !important;
  background: none !important;
  text-align: right !important;
} */


.hide-upload .ant-upload-list, .hide-upload .ant-upload-list-text{
  height: 200px;
  width: 200px;
  margin-top: 10px;
  display: none !important;
}


/* .ant-upload.ant-upload-drag{
  width: 80%;
  margin: 20px;
}*/
.ant-upload-list.ant-upload-list-picture{
  width: 100%;
  margin: 20px;
} 

.smallTable .ant-table-title {
  border-radius: 0px;
}

.smallTable th {
  font-size: 13px;
  overflow: auto;
  color: #aaa !important;
  font-weight: normal !important;
  padding: 4px 3px !important;
  padding-left: 8px !important;
  background-color: #fff !important;
}

.smallTable td {
  font-size: 11px !important;
  padding: 0px 0px !important;
  padding-left: 8px !important;
  background-color: #fff !important;
}

.mediumTable td {
  font-size: 11px !important;
  padding: 4px 0px !important;
  padding-left: 8px !important;
  background-color: #fff !important;
}

.smallTable .ant-table-footer {
  padding: 0px !important;
  background-color: #fafafa !important;
}

.smallTable .ant-table-title {
  padding: 0px !important;
  background-color: #fafafa !important;
}

.ant-tooltip-content{
  position: relative;
  top: 7px;
}

.ant-tooltip-arrow{
  margin-bottom: -8px;
}

.ant-tooltip{
  pointer-events: none !important;
}

.mediumTable .ant-table-title {
  border-radius: 0px;
}

.mediumTable th {
  font-size: 13px;
  overflow: hidden;
  color: #555 !important;
  font-weight: normal !important;
  padding: 4px 3px !important;
  padding-left: 8px !important;
  background-color: #fff !important;
}

/* Nth-Child row changing colours stuff */

.peerTable td {
  font-size: 12px !important;
  padding: 2px 1px !important;
  background-color: #fff;
  font-family: "Averta",
}

.peerTable tr:nth-child(2n) td {
  background-color: #e6f7ff;
  font-family: "Averta",
}

.peerTable th {
  font-size: 14px !important;
  padding: 2px 1px !important;
  font-family: "Averta",
}

.peerTable .ant-table-title {
  padding: 0px !important;
  
}

.peerTableRow {
  height: 14px;
  overflow: hidden;
}

.missingDataTable tr:nth-child(2n) td {
  background-color: #f0f0f0;
}

.commentsTable tr:nth-child(2n) td {
  background-color: #f0f0f0;
}

.mediumTable td {
  font-size: 11px !important;
  padding: 4px 0px !important;
  padding-left: 8px !important;
  background-color: #fff !important;
}

.mediumTable .ant-table-footer {
  padding: 0px !important;
  background-color: #fafafa !important;
}

.mediumTable .ant-table-title {
  padding: 0px !important;
  background-color: #fafafa !important;
}


.fullTable th {
  overflow: hidden;
  color: rgb(0, 0, 0, 0.7) !important;
  font-weight: normal !important;
  padding: 4px 3px !important;
  padding-left: 8px !important;
  background-color: #fff !important;
}

.fullTable td {
  padding: 4px 0px !important;
  padding-left: 8px !important;
  background-color: #fff !important;
}

.shiftUp .ant-tabs-content-top {
  margin-top: -17px;
}

.highlightRow{
  background-color: #ff0099;
  color: #ffffff;
}

.columnSplitter {
  border-right: 1px solid rgba(0,0,0,0.1);
  padding-right: 5px;
  margin-right: 5px;
}

/* .highlightRow td:nth-child(n-1) {
  color: #ffffff;
} */
  
.ant-table table {
  border-collapse: collapse;
}

.rowHighlightHover td:nth-child(n-1){
  transition: background-color 500ms linear;
}

.rowHighlightHover>td {
  background: unset !important;
}

.rowHighlightHover:hover td:nth-child(n-1){
  cursor: pointer;
  transition: background-color 500ms linear;
  background: #e6f7ff !important;
  color: #000;
}

.highlightBestSell{
  color: #A4031A;
}

.rowHover {
  transition: background-color 500ms linear;
}

.rowHover>td {
  background: unset !important;
}

.rowHover:hover {
  cursor: pointer;
  transition: background-color 500ms linear;
  background-color: #e6f7ff !important;
}

.defaultCursor {
  cursor: default !important;
}

.rowAdjusted {
  color: #C71585;
}

.rowReturns {
  color: #9932CC;
}

.rowFuture {
  color: #185285;
}

.rowReceived {
  color: #1581E9;
}

.rowSold {
  color: #202020;
}

.rowPeer {
  color: #A52A2A;
}

.rowBestSell {
  color: #F12A46;
}

.rowRawData {
  color: #800080;
}

.rowAdj {
  color: #C71585;
}

.rowRetn {
  color: #9932CC;
}

.rowRcvd {
  color: #237323;
}

.rowSold {
  color: #000000;
}

.isbnCount {
  color: #000000;
}

.noTitle {
  color: #FF0000;
}

.noImg {
  color: #800080;
}

.noDesc {
  color: #008000;
}

.noBio {
  color: #0000FF;
}


.noComparables {
  color: #a78518;
}

.noReviews {
  color: #007180;
}

.noDRC {
  color: #a74545;
}

.noInteriors {
  color: #4b3aac;
}


.evenRowColour {
  background-color: #a3a3a3;
}

.warningColor{
  color: #E77E21;
}

.disabledColor{
  color: #999999;
}

.futureColor{
  color: #008b7c !important;
}

.bestsellerColor{
  color: #F12A46;
}

.peerColor{
  color: #A52A2A;
}

.favColor{
  color: #ffcf17;
}

.highlightColor{
  color: #49b7e0;
}
.returnedColor{
  color: #9932cc;
}



.bmlbl {
  opacity: 0.6;
  font-style: italic;
}

.extra-peer {
  background-color: #fff;
  color: #A52A2A;
}

.extra-backroom {
  background-color: #b7e4f5;
  color: #000;
}

.extra-held {
  background-color: #ffb6c1;
  color: #000;
}

.extra-pending_return {
  background-color: #e0b9f4;
  color: #000;
}

.extra-special_order {
  background-color: #fbf6a2;
  color: #000;
}

.extra-total_onhand {
  background-color: #fff;
  font-weight: 500;
}

.extra-minimum_onhand {
  background-color: #a1a1a1;
  color: #000;
}

.extra-pending_onorder {
  background-color: #fff;
  font-weight: 500;
}


.extra-lbl-backroom {
  color: #21b4e7;
}

.extra-lbl-held {
  color: #f00;
}

.extra-lbl-pending_return {
  color: #9932cc;
}

.extra-lbl-special_order {
  color: orange;
}

.extra-lbl-total_onhand {
  color: #1b1b1b;
}

.extra-lbl-minimum_onhand {
  color: #1b1b1b;
}

.extra-lbl-pending_onorder {
  color: orange;
}


.cattabs .ant-tabs-nav{
  margin-bottom: -1px;
}

.extraTabs .ant-tabs-tab-active {
  border-bottom-color: #eee !important;
  color: #1b1b1b;
 
}

.extraTabs .ant-tabs-tab-active,
.dark .itemTabs .ant-tabs-tab-active small {
  color: #1b1b1b;
}

.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
  color: rgba(0, 0, 0, 0.85);
}

.columnHeaders .ant-table-thead {
  font-size: 10px;
}

.columnHeaders .ant-table-thead .ant-table-cell {
  background: none !important;
  padding: 0px !important;
  text-align: center;
}


.dark .genericInput {
  background-color: #262626;
  color: #fff;
}

.genericInput {
  background-color: #fff;
  color: #000;
}

.genericInput:focus-visible {
  outline: none;
}

.ant-tabs-tab-active {
  /* font-weight:bold; */
}

.bminput {
  color: #fff;
  margin-top: 1px;
  padding-right: 1px;
  background-color: #0640a0;
}

.bminput input {
  border: none;
  border-radius: 0px;
  background-color: #1581e9;
  color: #fff;
}

.ant-tag-blue {
  background-color: #fff;
}

.ant-btn-fake-disabled {
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
}


.itemHistory th {
  font-size: 13px;
  overflow: hidden;
  color: #aaa !important;
  font-weight: normal !important;
  padding: 0px 2px !important;
  background-color: #fff !important;
  text-align: right !important;
}

.itemHistory td {
  font-size: 9px;
  padding: 0px 2px !important;
  background-color: #fff !important;
  text-align: right !important;
}


.itemHistory {
  border: 1px solid #eee;
  border-bottom: 0px;
}

.itemHistory .ant-table-tbody>tr.ant-table-row>td {
  font-size: 11px !important;
}

.ant-anchor-wrapper {
  background: none;
}

.ant-anchor-ink {
  display: none;
}

.ant-anchor-link {
  padding-bottom: 0px;
  padding-top: 5px;
}



.closebar {
  display: block;
  background-color: #fcfcfc;
  margin: 0 -20px;
  /* margin-bottom: 20px; */
  margin-top: 5px;
  border: 1px solid #eee;
  border-left: 0px;
  border-right: 0px;
}

.menu-anchor-item {
  background-color: #0842a0;
  font-size: 14px;
  color: #fff;
  text-align: center;
  font-weight: 500;
  padding: 3px 0px;
}

.ant-anchor-link-title-active .menu-anchor-item {
  background-color: #1890ff;
}



.endbreak {
  display: block;
  margin: 0 -20px;
  margin-top: 20px;
  margin-bottom: 0px;
  /* border-top: 1px solid #eee;  */
  max-height: 1px;

}

.ant-collapse>.panel-filter,
.ant-collapse>.panel-filter>.ant-collapse-header {
  font-weight: normal !important;
  color: #888;
}

.ant-collapse>.panel-filter>.ant-collapse-header .ant-collapse-arrow {
  padding-top: 0px;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  left: 1px;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header {
  padding-top: 12px;
  padding-bottom: 0px;
  padding-left: 16px;
  font-size: 12px;
  font-weight: 500;
}

.ant-collapse>.panel-filter>.ant-collapse-header {
  padding-top: 0px;
}

.mini-checkbox .ant-checkbox-inner,
.ant-checkbox-input {
  transform: scale(0.75);
  border-color: #999;
}

.mini-radio .ant-radio-inner,
.ant-radio-input {
  transform: scale(0.85);
  border-color: #eee;
}



.loading {
  opacity: 0.5;
  pointer-events: none;
  -webkit-transition: opacity .3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -moz-transition: opacity .3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -ms-transition: opacity .3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: opacity .3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: opacity .3s cubic-bezier(0.645, 0.045, 0.355, 1);

}

.loading-done {
  opacity: 1;
  pointer-events: inherit;
  -webkit-transition: opacity .3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -moz-transition: opacity .3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -ms-transition: opacity .3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: opacity .3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: opacity .3s cubic-bezier(0.645, 0.045, 0.355, 1);
}


.linkHover:hover {
  background-color: #0b4ab0;
}

.linkHover {
  cursor: pointer;
  background-color: #0842a0;
}

.bmLabel {
  color: #888;
}

.bmValue {
  color: #1b1b1b;
}

.bc {
  border-color: #eee !important;
}

.bc2 {
  border-color: #fff !important;
}

.bc3 {
  border-color: #808080 !important;
}


.bc-light {
  border-right-color: #eee !important;
  border-bottom-color: #eee !important;
  border-top-color: #eee !important;
}

.bdr {
  border: 1px solid #eee;
}

.cataloguebtn {
  background-color: #fdfdfd !important;
  border: 1px solid #ddd;
  color: #6b6b6b;
  font-size: 12px !important;
}

.cataloguebtn:hover {
  filter: brightness(1.0);
  color: #000;
  background-color: #fff !important;
  border: 1px solid #ccc;
}

.redPlaceholder .ant-select-selection-placeholder {
  color: #a61d24 !important;
}


.ant-input:-ms-input-placeholder {
  color: #a3a3a3;
}
.ant-input::placeholder {
  color: #a3a3a3;
}

.ant-input.ant-input-sm::placeholder {
  color: #a3a3a3;
  font-size: 12px;
}
.ant-select-selection-placeholder{
  color: #a3a3a3;
  font-size: 12px;
}

.ant-tooltip-inner {
  line-height: 14px;
}

.tag-btn {
  cursor: pointer;
}

.tag-btn:hover {
  filter: brightness(1.1);
}

.lighten:hover {
  filter: brightness(80%);
  transition: .8s;
}

.ant-btn-primary {
  text-shadow: none;
  box-shadow: none;
}

tr.ant-table-expanded-row .ant-descriptions-view table {
  border-collapse: collapse;
}



.tiny-radio {
  height: 17px !important;
  padding: 0px 7px !important;
  line-height: 12px !important;
  font-size: 13px !important;
}


.tiny-btn.ant-btn-sm {
  height: 17px;
  padding: 0px 3px;
  font-size: 13px;
  line-height: 6px;
  border-radius: 2px;
  margin-left: 1px;
  text-shadow: none;
  box-shadow: none;
  margin: 2px 0px;
}

.tiny-btn>.ant-btn-sm {
  height: 17px;
  padding: 0px 3px;
  font-size: 13px;
  line-height: 6px;
  border-radius: 2px;
  margin-left: 1px;
  text-shadow: none;
  box-shadow: none;
  margin: 2px 0px;
}



.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before{
  font-weight: bold;
  color: #f00;
}
.pub{
  filter: brightness(1);
  transition: filter 200ms ease; 
}

.pub:hover{
  filter: brightness(.98);
  transition: filter 200ms ease; 
}

.pub-raw{
  background-color: #ffb86611;
  color: #63581f;
}

.pub-raw .imprint{
  background-color: #d9b98233;
  color: #63581f;
}

.pub-raw .pub-text{
  color: #63581f;
}

.dark .pub-raw{
  background-color: #ffb86611;
  color: #d5a261;
}

.dark .pub-raw .imprint{
  background-color: #d9b98211;
  color: #d5a261;
}

.dark .pub-raw .pub-text{
  color: #d5a261;
  
}


.dark .pub-moved{
  background-color: #1f271f;
  color: #90d397;
}
.dark .pub-moved .pub-text{
  color: #90d397;
}

.dark .pub-dirty{
  background-color: #252120;
  color: #ef8181;
}
.dark .pub-dirty .pub-text{
  color: #ef8181;
}

.hover-highlight .ant-input-affix-wrapper, .hover-highlight input {
  background-color: #fbf3f1 !important; /* Change the input field's background */
}

.dark .hover-highlight .ant-input-affix-wrapper, .dark .hover-highlight input {
  background-color: #252120 !important; /* Change the input field's background */
}



.hover-highlight .ant-input-affix-wrapper{
  border: 1px solid #954040 !important
}

.dropping{
  background-color: #f1f6fb !important;
}


.pub-moved{
  background-color: #f1fbf2;
  color: #1f6326;
}
.pub-moved .pub-text{
  color: #1f6326;
}

.pub-dirty{
  background-color: #fbf3f1;
  color: #954040;
}
.pub-dirty .pub-text{
  color: #954040;
}


.popover-noicon .ant-popover-message-title {
  padding-left: 0px;
}

.popover-noicon .ant-popover-buttons {
  margin-left: -6px;
}


.mini-btn.ant-btn-sm {
  height: 18px;
  padding: 0px 4px;
  font-size: 13px;
  line-height: 10px;
  border-radius: 2px;
  text-shadow: none;
  box-shadow: none;
}

.minselect .ant-select-arrow {
  font-size: 12px !important;
  font-weight: bold;
  top: 12px !important;
  right: 5px !important;
}


.minselect .ant-select-selector {
  height: 19px !important;
  padding: 0px 3px !important;
}

.minselect .ant-select-selector .ant-select-selection-item {
  line-height: 14px !important;
  padding-right: 18px !important;
}

.minselect .ant-select-selector .ant-select-selection-search {
  line-height: 14px !important;
}


/* .minscroll::-webkit-scrollbar {

  width: 0px;
  height: 0px;
} */

.ant-card-meta-detail>div:not(:last-child) {
  margin-bottom: 0px;
}

.smallcardgrid {
  display: grid;
  grid-gap: 0px;
  justify-content: space-between;
  align-items: flex-start;
  grid-template-columns: repeat(auto-fill, minmax(60px, 115px));
}

.smallcardgrid .ant-card-body{
  padding: 5px;
}
.smallcardgrid .c, .smallcardgrid .c a, .smallcardgrid .c small{
  font-size: 12px !important;
}

.cardgrid {
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(auto-fit, minmax(210px, 220px));
}

.gcardgrid {
  display: grid;
  grid-gap: 50px;
  justify-content: space-between;
  align-items: flex-start;
  grid-template-columns: repeat(auto-fit, minmax(365px, 280px));
}

.grgrid {
  display: grid;
  justify-content: space-between;
  align-items: flex-start;
  grid-gap: 20px;
  /* grid-template-columns: repeat(auto-fit, minmax(295px, 595px)); */
  /* grid-template-columns: repeat(auto-fit, minmax(295px, 295px)); */
}


.card-selected {
  background-color: #dcf4ff;
}

@media print {

  .no-print,
  .no-print * {
    display: none !important;
  }
}


.detailview .ant-tabs-card .ant-tabs-content>.ant-tabs-tabpane {
  background: #fff;
}

.detailview .ant-tabs-card>.ant-tabs-nav::before {
  display: none;
}

.detailviewr .ant-tabs-card .ant-tabs-tab,
[data-theme='compact'] .detailview>.ant-tabs-card .ant-tabs-tab {
  background: transparent;
  border-color: transparent;
}

.detailview .ant-tabs-card .ant-tabs-tab-active,
[data-theme='compact'] .detailview>.ant-tabs-card .ant-tabs-tab-active {
  background: #fff;
  border-color: #eee;
}

.ant-table.ant-table-small .ant-table-title,
.ant-table.ant-table-small .ant-table-footer,
.ant-table.ant-table-small .ant-table-thead>tr>th,
.ant-table.ant-table-small .ant-table-tbody>tr>td,
.ant-table.ant-table-small tfoot>tr>th,
.ant-table.ant-table-small tfoot>tr>td {
  padding: 8px 7px;
  line-height: 17px;
}

.ant-table.ant-table-small .ant-table-title,
.ant-table.ant-table-small .ant-table-footer,
.ant-table.ant-table-small .ant-table-thead>tr>th,
.ant-table.ant-table-small tfoot>tr>th {
  line-height: 16px;
}

.paginate {
  background-color: #fcfcfc;
  margin: 0px 0px;
  border-bottom: 1px solid #eee;
}

.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-label,
.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-content {
  padding: 0px 2px;
  font-size: 14px;
  line-height: 18px;
  text-align: left;
}

.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-label {
  font-size: 9px;
  color: #777;
}

.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-content {
  font-weight: normal;
  color: rgb(110, 110, 110);
}

.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-content div {
  margin: 0px -2px;
  padding: 0px 2px;
  color: rgb(255, 255, 255);
}

.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-content span {
  text-align: right;
  display: block;
}



.mini .ant-table.ant-table-small .ant-table-thead>tr>th {
  padding: 0px 2px;
  font-size: 13px;
}


.mini .ant-table.ant-table-small .ant-table-tbody>tr>td {
  padding: 0px 2px;
  font-size: 11px;
}

.center-heading.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-label {
  text-align: center;
}

.ant-descriptions-bordered .ant-descriptions-row {
  border: none;
}

.ant-descriptions-bordered .ant-descriptions-row:first-child {
  border-bottom: 1px solid #eee !important;
}

.vert .ant-descriptions-item-content {
  text-align: center;

}


.ant-table-selection-col,
.ant-table-selection-column {
  display: none;
}

.ant-descriptions-bordered .ant-descriptions-view {
  border: none;
}

.ant-descriptions-bordered .ant-descriptions-item-content {
  background-color: #fff;
  border: none;
}

.ant-descriptions-bordered .ant-descriptions-item-label {
  background-color: #fff;
  border: none;
}

.ant-table.ant-table-small .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
  margin: 0px;
}

.ant-menu-submenu-popup {
  /* display: none; */
}

.small-collapse>.ant-collapse-item.ant-collapse-no-arrow>.ant-collapse-header {
  padding: 0px 0px !important;
}

.ant-collapse>.ant-collapse-item.ant-collapse-no-arrow>.ant-collapse-header {
  padding: 5px 0px;
}


.ant-collapse-ghost>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
  padding: 0px;
}

.ant-comment-inner {
  padding: 0px;
}


.ant-menu-inline-collapsed {
  width: 60px;
}

.ant-table-tbody>tr.ant-table-row>td {
  font-size: 12px;
}

.ant-descriptions-header {
  margin: 3px 0px;
}

.ant-descriptions-title {
  font-size: 11px;
}

.ant-descriptions-item-label {
  text-align: center;
}

.ant-descriptions-item-content input,
.ant-descriptions-item-content .ant-tag,
.ant-descriptions-item-content .ant-select {
  font-size: 11px;
  line-height: 18px;
}

.ant-descriptions-item-content .ant-form-item-label,
.ant-descriptions-item-content .ant-form-item {
  padding: 0px;
  margin-bottom: 0px;
}

.ant-descriptions-view {
  border-radius: 0px;
}

.small .ant-select-selector {
  font-weight: bold;
  padding: 0px 7px;
}

.small .ant-select-selection-item {
  padding-right: 15px;

}

.ant-select-suffix{
  font-weight: bold;
}

.small .ant-select-arrow {
  font-size: 12px;
  font-weight: bold;
  right: 6px;
  top: 60%;
}

/* MH  for #3341 */
.ant-select-with-custom-arrow .ant-select-arrow .anticon {
	color:#000;
  font-size: "16px"; 
}

.ant-select.ant-select-with-custom-arrow .ant-select-selection-placeholder{
  font-size: 14px !important;
}

.ant-select-sm.ant-select-with-custom-arrow .ant-select-selection-placeholder{
  font-size: 12px !important;
}

.select-all-checkbox .ant-checkbox-inner {
  border: 2px solid #1890ff; /* Green border for differentiation */
}



.ant-select-custom-arrow {
  font-size: "16px";
  color: #000;
  pointer-events: none;
}

.card-fallback{
  background-color: #c1c8d3dd;
  padding: 15px;
  line-height: 14px;
  color: #000 !important;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 330px;
}

.small-card-grid-fallback{
  background-color: #c1c8d3dd;
  padding: 10px;
  line-height: 11px;
  color: #000 !important;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  height: 170px;
}

.card-fallback-small{
  background-color: #c1c8d3dd;
  padding: 5px;
  line-height: 12px;
  color: #000 !important;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 330px;
}

.card-fallback-summary{
  background-color: #c1c8d3dd;
  padding: 15px;
  line-height: 12px;
  color: #000 !important;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
}

/*
Slider
*/


.slider-wrapper {
  overflow-x: hidden;
  overflow-y: hidden;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  width: fit-content;
  padding-bottom: 10px;
}


.slider-container {
  overflow-x: hidden;
  overflow-y: hidden;
}

.slide {
  float: left;
  padding: 5px 0px;
  margin: 0px;
  overflow: hidden;
  height: 160px;
  transition-duration: 0.5s;
}

.slide:hover {
  transform: translate(0px, -4px);
  transition-duration: 0.1s;
}

.slide img {
  pointer-events: none;
}

.slider-wrapper:first-child {
  overflow: visible;
}


.ant-tabs-card.ant-tabs-small>.ant-tabs-nav .ant-tabs-tab {
  padding: 0px 8px;
}

/* COLORS */

.adjusted_color {
  color: #C71585;
}

.returns_color {
  color: #9932CC;
}

.received_color {
  color: #228B22;
}

.sold_color {
  color: #000000;
}

.peer_color {
  color: #A52A2A;
}

.sales-Held {
  font-weight: 600;
}

.sales-Ship {
  font-weight: 600;
}

.sales-Active {
  color: #e77e21;
  font-weight: 600;
}


.sales-Rcvd {
  color: #228B22;
}

.sales-Retn {
  color: #C71585;
}


.htmlcolor {
  color: #666;
}

/* BM */
.td.ant-table-column-sort {
  background-color: #fff;
}

.c {
  color: #000;
}
.c2 {
  color: rgba(0, 0, 0, .7);
}

.logotext {
  text-align: center;
  font-family: 'Bowlby One SC', cursive;
  font-size: 1.5vw;
}

.trans {
  transition: opacity 0.3s;
}

.float-flex{
  display: flex;
  justify-content: space-between;
}

.rep {
  background-color: #fff !important;
  border: 1px solid #eee !important;
}

.inner-dashed-border {
  border: 2px dashed #888;
  padding: 5px;
  box-sizing: border-box;
  position: relative;
}



.sub-filter{
  border: 1px dashed #b7eb8f;
}
.bcg {
  background-color: #eee;
}

.bcg2 {
  background-color: #fff;
}

.bcg3 {
  background-color: #fcfcfc;
}

.bcg4 {
  background-color: #eee;
}

.dark .bcg5 {
  background-color: #fff;
}



.folders {

  font-weight: 500;
  padding: 9px 20px;
  padding-right: 8px;
  background-color: #fafafa;
  border-bottom: 1px solid #eee;
}

.sml {
  font-size: 11px;
  line-height: 22px;
}

.hide_hover .ant-table-expanded-row .ant-table-cell {
  border: none;
}

.hide_hover .ant-table-expanded-row .ant-table-cell:hover {
  background-color: #fff;
}

.ant-table-expanded-row .ant-table-thead .ant-table-cell:hover {
  background-color: #fafafa;
}

.nofocus:focus {
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.85);
}

.mask-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(51, 51, 51, 0.0);
  z-index: 10;
}

.description p{
  font-weight: normal;
  line-height: 14px;
}

.description html{
  font-weight: normal;
}

tr.drop-over-downward td {
  border-bottom: 1px dashed #3ea7ce;
  border-top: 1px dashed #3ea7ce;
}

tr.drop-over-upward td {
  border-top: 1px dashed #3ea7ce;
}


.nowrap80 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 80px;
}


.nowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
}

.nowrap150 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

.nowrap250 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.galleryButton {
  position: relative;
  left: 10px;
  cursor: pointer;
  color: #fff;
  font-size: 30px;
  transition: 0.3s;
  padding: 20px;
  opacity: 0.2;
}

.galleryButton:hover {
  color: #fff;
  transition: 0.3s;
  opacity: 0.4;
}

.light {
  font-weight: lighter;
  font-size: smaller;
}

p {
  line-height: 25px;
  font-size: 12px;
}

p strong {
  color: #000;
}

.html li {
  padding: 5px;
}

.hlw {
  text-decoration: underline;
  font-weight: bold;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
          line-clamp: 2;
  -webkit-box-orient: vertical;
}

.briefTitleTruncate {

  overflow: hidden;
  text-overflow: ellipsis;
  color: blue;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
          line-clamp: 2;
  -webkit-box-orient: vertical;
}

.bmcard {
  width: 310px;
  display: block;
  height: 140px;
}

kbd {
  display: inline-block;
  margin: 0 0;
  padding: 0px 5px;
  font-size: 13px;
  color: #fff;
  background-color: #000;
  border: 1px solid var(--black-300);
  border-radius: 3px;
  overflow-wrap: break-word;
  line-height: 20px;
}

.bm-warning {
  color: #f12a46;
  border-top: 3px solid #f12a46;
  border-bottom: 3px solid #f12a46;
  display: block;
  padding: 15px 0px;
  font-weight: normal;
  margin: 30px 0px;
}

.bm-warning kbd {
  background-color: #f12a46;
}

.bm-notice kbd {
  background-color: #1890ff;
}

.bm-notice {
  color: #1890ff;
  border-top: 3px solid #1890ff;
  border-bottom: 3px solid #1890ff;
  display: block;
  padding: 15px 0px;
  font-weight: normal;
  margin: 30px 0px;
}

/* Overrides */

.subtle {
  opacity: 0;
}


.ant-divider-horizontal.ant-divider-with-text-left::before {
  top: 50%;
  width: 1%;
}

.ant-divider-horizontal.ant-divider-with-text-left::after {
  top: 50%;
  width: 99%;
}



.ant-card-hoverable:hover {


  -webkit-box-shadow: 0px 0px 20px 3px #000000;
  box-shadow: 0px 3px 15px 1px #eee;
}

.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin: 0px;
}

.ant-menu-sub.ant-menu-inline>.ant-menu-item,
.ant-menu-sub.ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title {
  height: auto;
  line-height: 30px;
  padding-left: 35px !important;
  overflow: visible;
  text-overflow: initial;
  white-space: pre-wrap;
  margin-bottom: 0px;
  margin-top: 0px;
}

.ant-anchor-link-title {
  text-overflow: initial;
  white-space: pre-wrap;
  font-size: 13px;
  padding-left: 15px;
}

.ant-menu-sub.ant-menu-inline>.ant-menu-item,
.ant-menu-sub.ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title a {
  line-height: 14px;
  padding-bottom: 10px;
  font-size: 13px;
  padding-top: 10px;
}

.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: #fff;
}

#components-layout-demo-top .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.ant-collapse-icon-position-right>.ant-collapse-item>.ant-collapse-header {
  font-weight: 600;
  border-bottom: 1px solid #eee;
  color: #555;
  padding: 12px 12px 12px 0px;
}


.inputsmall input {
  font-size: 12px;
  line-height: 22px;
}

.noteSelector {
  border-radius: none;
  height: 15px;
}

.noteSelector .ant-select-selector {
  border: none !important;
  border-radius: 0px !important;
  height: 20px !important;
  line-height: 20px !important;
}

.ant-tabs-card>.ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card>div>.ant-tabs-nav .ant-tabs-tab-active {
  background: #eee;
  border-color: #eee;
}


.noteSelector .ant-select-selection-placeholder {
  line-height: 20px !important;
}

.noteSelector input {
  font-size: 12px;
  line-height: 20px;
}


.categorySelector input {
  font-size: 12px;
  line-height: 20px !important;
  border: none;
}

/* End Overrides */


.hideHover {
  background-color: #fff;
}

.hideHover:hover {
  background-color: #fff;
}


.rowHide {
  display: none;
}

.rowShow {
  display: table-row;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
}

.orderBoxTable table {
  border: 1px solid #eee;
  border-bottom: 0px;
}

.orderBoxTable table th {
  color: #888;
  line-height: 3px;
  text-align: center;
  font-size: 11px;
}

.orderBoxTable table td:first-child {
  padding-left: 2px !important;
}

.orderBoxTable table td:last-child {
  padding-right: 4px !important;
}

.orderBoxTable table td {
  color: #888;
  line-height: 5px;
  font-size: 11px !important;
  padding: 4px 2px !important;
  text-align: center;
}

.tborder {
  border: 1px solid #eee;
}

.tborder2 {
  border: 1px solid #eee;
}

.tborder3 {
  border: 1px solid #eee;
}

.tborder2 .ant-table-title {
  padding: 0 !important;
  border-bottom: 1px solid #eee;
}

.tborder3 .ant-table-title {
  padding: 0 !important;
  border-bottom: 0px;
}

.notReceivedFile {
  background-color: yellow !important; 
}

.errorFile {
  background-color: #ff9797 !important; 
}



.tableBorder table {
  border: 1px solid #eee;
  border-bottom: 0px;
}

.cellBorder {
  border-right: 1px solid #eee;
  font-weight: 600px;
}

.cellBorderBold {
  border-right: 1px solid #bbb;
  font-weight: 600px;
}


.cborder .ant-collapse-header {
  border-bottom: 1px solid #eee;
}


.tableNoBorder table {
  border: none;
}

.ant-table-expanded-row .ant-table-cell:hover {
  background-color: #fff;
}

.stock {
  text-transform: capitalize;
}

.stock span {
  display: block;
  text-transform: capitalize;
}

.ant-carousel .slick-list .slick-slide {
  pointer-events: auto !important;
}

.slick-current{
  z-index: 100;
}


.count {
  font-size: 9px;
  color: #888;
  float: right;
}

p {
  color: rgb(99, 99, 99);
}

.flourish {
  background-image: linear-gradient(to left, #0742a0, #097ae6);
}

.no-cover {
  min-width: 280px;
  min-height: 180px;
  background-image: radial-gradient(ellipse at bottom, #097ae6, #0742a0);
}

.cta {
  background-image: radial-gradient(ellipse at top, #097ae6, #0742a0);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ctaSearch {
  border: none;
  height: 50px;
}

.afix .ant-affix {
  background-color: #fcfcfc;
  border-bottom: 1px solid #eee;
  box-shadow: none;
}


.shadow {
  -webkit-box-shadow: 0px 2px 10px -10px #000000;
  box-shadow: 0px 2px 10px -10px #000000;
}

.shadow-large {
  -webkit-box-shadow: 0px 3px 6px -1px #CCC;
  box-shadow: 0px 3px 6px -1px #CCC;
}


.lightshadow {
  text-align: center;
  -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
}

.headerbg {
  background-color: #fff;
  ;
}


.hide {
  display: inline;
}

.show {
  display: none;
}

.hide-block {
  display: block;
}

.show-block {
  display: none;
}

.hide-mobile-block {
  display: block;
}

.show-mobile-block {
  display: none;
}

.hide-mobile-block {
  display: block;
}

.show-mobile-block {
  display: none;
}

.borderLeft {
  border-left: 1px solid #eee;
}

.borderTop {
  border-top: 1px solid #eee;
}

.borderBottom {
  border-bottom: 1px solid #eee;
}

.filterTag {
  background-color: #fff;
}

.adjust-padding {
  padding: 0px 50px;
}

.adjust-margin {
  margin: 0px 50px;
}

.shim {
  height: 5px;
  display: block;
}

.print-card{
  border: none;
  padding: none;
}

.print-card .ant-card-body{
  padding: 0px !important;
}

.shimbg {
  background-color: #fff;
}

.logo {
  width: 101px;
  height: 101px;
}

.ar {
  text-align: right;
}

.adjust-content-padding {
  padding: 30px;
}

.mobile-centerR {
  text-align: right;
}

.mobile-centerL {
  text-align: left;
}

.mobile15 {
  padding: 15px;
}

.small-empty {
  margin: 3px;
}

.summary-box div {
  display: flex;
  align-items: center;
  ;
  min-height: 20px;
  font-size: 11px;
  justify-content: space-between;
  padding: 0px 5px;
  text-align: right;
}

.player-wrapper{
  position: relative;
  padding-top: 56.25%;
}

.react-player{
  position: absolute;
  top: 0;
  left: 0;
}

.summary-box div:nth-of-type(odd) {
  background-color: #fafafa;
}

.summary-box div:nth-of-type(even) {
  background-color: #fff;
}

.dimmed {
  opacity: 0.7;
}

.hidecontrols .ant-input-number-handler-wrap {
  display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  border-radius: 0;
}

.customerCart .ant-table-title {
  background-color: #fafafa;
  border-bottom: 1px solid #eee;
}

.clean-input .ant-input-group-addon:first-child {
  border: none;
  border-radius: 2px 0px 0px 2px;
}

.clean-input .ant-input-affix-wrapper {
  border: 1px solid #fff !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.details-box div {
  display: flex;
  align-items: center;
  ;
  min-height: 40px;
  justify-content: space-between;
  padding: 0px 15px;
  text-align: right;
}

.details-box div:nth-of-type(odd) {
  background-color: #eee;
}

.details-box div:nth-of-type(even) {
  background-color: #fff;
}

.custom-shadow{
  box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 6px -4px, rgba(0, 0, 0, 0.08) 0px 6px 16px 0px, rgba(0, 0, 0, 0.05) 0px 9px 28px 8px;
}

@media only screen and (max-width: 700px) {

  
  .switchbanner{
    position: absolute !important;
    width: 1300px !important;
  }
  .ant-affix{
    position: absolute !important;
    width: auto !important;
    top: 230px !important;
   
  }

  .navWrapper {
    position: relative !important;
    width: 1300px !important;
  }
  
  



  .mobile-centerR {
    text-align: center;
  }

  .mobile-centerL {
    text-align: center;
  }

  .adjust-content-padding {
    padding: 0px;
  }

  .bmcard {
    width: 100%;
  }

  .ant-space-item {
    width: 100%;
  }

  .hide-mobile {
    display: none;
  }

  .show-mobile {
    display: inline;
  }

  .hide-mobile-block {
    display: none;
  }

  .show-mobile-block {
    display: block;
  }

}


.filter-hide {
  display: block;
}

body {
  background-color: #f0f2f5;
  font-family: "Averta";
}


@media only screen and (max-width: 1300px) {

  .filter-hide {
    display: none;
  }


}

@media print {
  .pagebreak { page-break-before: always; } 

  .avoid-cut {
    break-inside: avoid;
  }

}



@media only screen and (max-width: 1050px) {


  .mobile15 {
    padding: 0px;
  }

  .logo {
    width: 70px;
    height: 70px;
  }

  .adjust-padding {
    padding: 0px 20px;
  }

  .adjust-margin {
    margin: 0px 0px;
  }


  .hide {
    display: none;
  }

  .show {
    display: inline;
  }

  .hide-block {
    display: none;
  }

  .show-block {
    display: block;
  }


}