.medium-toolbar-arrow-under:after {
    top: 40px;
    border-color: #1b1b1b transparent transparent transparent; }
  
  .medium-toolbar-arrow-over:before {
    top: -8px;
    border-color: transparent transparent #1b1b1b transparent; }
  
  .medium-editor-toolbar {
    background-color: #1b1b1b; }
    .medium-editor-toolbar li {
      padding: 0; }
      .medium-editor-toolbar li button {
        min-width: 40px;
        height: 40px;
        line-height: 10px;
        border: none;
        border-right: 1px solid #1b1b1b;
        background-color: transparent;
        color: #fff;
        -webkit-transition: background-color .2s ease-in, color .2s ease-in;
                transition: background-color .2s ease-in, color .2s ease-in; }
        .medium-editor-toolbar li button:hover {
          background-color: #5a5a5a;
          color: #fff; }
      .medium-editor-toolbar li .medium-editor-button-active {
        background-color: #424242;
        color: #fff; }
      .medium-editor-toolbar li .medium-editor-button-last {
        border-right: none; }
  
  .medium-editor-toolbar-form .medium-editor-toolbar-input {
    height: 40px;
    background: #1b1b1b;
    color: #fff; }
    .medium-editor-toolbar-form .medium-editor-toolbar-input::-webkit-input-placeholder {
      color: #fff;
      color: rgba(255, 255, 255, 0.8); }
    .medium-editor-toolbar-form .medium-editor-toolbar-input:-moz-placeholder {
      /* Firefox 18- */
      color: #fff;
      color: rgba(255, 255, 255, 0.8); }
    .medium-editor-toolbar-form .medium-editor-toolbar-input::-moz-placeholder {
      /* Firefox 19+ */
      color: #fff;
      color: rgba(255, 255, 255, 0.8); }
    .medium-editor-toolbar-form .medium-editor-toolbar-input:-ms-input-placeholder {
      color: #fff;
      color: rgba(255, 255, 255, 0.8); }
  
  .medium-editor-toolbar-form a {
    color: #fff; }
  
  .medium-editor-toolbar-anchor-preview {
    font-size: 12px;
    line-height: 10px;
    margin: 5px;
    margin-top: 0px;
    background: #1b1b1b;
    color: #fff; }
  
  .medium-editor-placeholder:after {
    color: #c7c7c7; }

   .medium-editor-element:focus-visible{
    outline: none;
   }
   .dark .medium-editor-element{
    font-size: 14px !important;
    color: #cecece !important;
   }
   .dark .medium-editor-element p{
    font-size: 14px !important;
    color: #cecece !important;
   }

   .medium-editor-element{
    min-height: 125px !important;
    margin-bottom: 0px !important;
    font-size: 14px !important;
    color: #1b1b1b !important;
   }
   .medium-editor-element p{
    font-size: 14px !important;
    color: #1b1b1b !important;
   }

   .medium-editor-action-anchor{
    color: #1b1b1b00 !important;
    background-image: url("media/linkicon.png") !important;
    background-repeat: no-repeat;
    background-position: center;
   }

   .dark .medium-editor-placeholder::after, .dark .medium-editor-placeholder-relative::after { font-style: normal !important; color: rgba(255, 255, 255, 0.3) !important; }
   
   .medium-editor-placeholder::after, .medium-editor-placeholder-relative::after { white-space: pre-wrap; font-style: normal !important; color: rgba(0, 0, 0, 0.3) !important; }
   